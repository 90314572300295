<template>
<div class="container-fluid">
<breadcrumb>
      <img
        src="static/img/metric.svg"
        class="breadcrumb-image"
      />
      <breadcrumb-item>
        <router-link to="/operationaleffectiveness">Select Entity</router-link>
      </breadcrumb-item>
      <breadcrumb-item>
        <router-link to="..">Operational Effectiveness</router-link>
      </breadcrumb-item>
      <breadcrumb-item active>Edit Operational Effectiveness Assessment</breadcrumb-item>
    </breadcrumb>
  <div class="row">
    <div class="col-md-12">
        <form autocomplete="off">
          <card v-if="loaded">
            <div>
              <template v-if="model.percentageCompleted == 0">
              <fg-input label="Control Framework" :error="getError('control framework')">
                <el-select
                  name="control framework"
                  v-validate="modelValidations.controlFramework"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlControlFrameworkChange"
                  v-model="model.controlFrameworkId"
                  placeholder="Select Control Framework"
                >
                  <el-option
                    class="select-default"
                    v-for="item in controlFrameworks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </fg-input>
              </template>
              <template v-else>
              <fg-input label="Control Framework" :error="getError('control framework')">
                <el-select
                  name="control framework"
                  v-validate="modelValidations.controlFramework"
                  class="select-default"
                  style="width: 100%"
                  :disabled="true"
                  v-on:input="ddlControlFrameworkChange"
                  v-model="model.controlFrameworkId"
                  placeholder="Select Control Framework"
                >
                  <el-option
                    class="select-default"
                    v-for="item in controlFrameworks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </fg-input>
              </template>
              <fg-input label="Description">
                <textarea
                  name="Description"
                  v-model="model.description"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <checkbox v-model="checkboxValue">Active</checkbox>
              <div class="text-right">
              <button type="button" @click="close" class="btn btn-fill btn-danger btn-wd">
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>
                Cancel
              </button>
              <button
                type="submit"
                @click.prevent="validate"
                @click="save"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>
                Save
              </button>
            </div>
            </div>
          </card>
        </form>

        <card v-if="loaded">
          <div>
            <div class="col-sm-12">
              <el-table v-loading="loading" style="width: 100%;" :data="model.controlFrameworkCategories">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :width="200" label="Weighting">
                  <template slot-scope="props">
                    <template v-if="model.percentageCompleted == 0">
                      <fg-input
                        name="weighting"
                        style="height: 25px"
                        :error="getError('weighting')"
                      >
                        <el-input-number
                          v-model="props.row.weighting"
                          :max="5"
                          :min="0"
                          v-validate="{required:true,  
                          between: [0, 5]}"
                          name="weighting"
                        ></el-input-number>
                      </fg-input>
                    </template>
                    <template v-else>
                      <fg-input
                        name="weighting"
                        style="height: 25px"
                        :error="getError('weighting')"
                      >
                        <el-input-number
                          v-model="props.row.weighting"
                          :disabled="true"
                          :max="5"
                          :min="0"
                          v-validate="{required:true,  
                          between: [0, 5]}"
                          name="weighting"
                        ></el-input-number>
                      </fg-input>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column :width="200" label="Target Score">
                  <template slot-scope="props">
                    <template v-if="model.percentageCompleted == 0">
                      <fg-input
                        name="targetScore"
                        style="height: 25px"
                        :error="getError('targetScore')"
                      >
                        <el-input-number
                          v-model="props.row.targetScore"
                          :max="100"
                          :min="0"
                          v-validate="{required:true,  
                          between: [0, 100]}"
                          name="targetScore"
                        ></el-input-number>
                      </fg-input>
                    </template>
                    <template v-else>
                      <fg-input
                        name="targetScore"
                        style="height: 25px"
                        :error="getError('targetScore')"
                      >
                        <el-input-number
                          v-model="props.row.targetScore"
                          :disabled="true"
                          :max="100"
                          :min="0"
                          v-validate="{required:true,  
                          between: [0, 100]}"
                          name="targetScore"
                        ></el-input-number>
                      </fg-input>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column :width="200" fixed="right" label="Assigned To">
                  <template slot-scope="props">
                    <template v-if="model.percentageCompleted == 100">
                    <fg-input style="height: 25px" :error="getError('assigned to')">
                      <el-select
                        name="assigned to"
                        v-validate="{required:true}"
                        class="select-default"
                        style="width: 100%"
                        :disabled="true"
                        v-model="props.row.userId"
                        v-on:input="ddlUserChange"
                        placeholder="Select User"
                      >
                        <el-option
                          class="select-default"
                          v-for="item in users"
                          :key="item.id"
                          :label="item.firstName + ' ' + item.lastName"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </fg-input>
                    </template>
                    <template v-else>
                     <fg-input style="height: 25px" :error="getError('assigned to')">
                      <el-select
                        name="assigned to"
                        v-validate="{required:true}"
                        class="select-default"
                        style="width: 100%"
                        v-model="props.row.userId"
                        v-on:input="ddlUserChange"
                        placeholder="Select User"
                      >
                        <el-option
                          class="select-default"
                          v-for="item in users"
                          :key="item.id"
                          :label="item.firstName + ' ' + item.lastName"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </fg-input>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Table, TableColumn, Select, Option } from "element-ui";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";
import $ from "jquery";

export default {
  components: {
    Checkbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Breadcrumb,
    BreadcrumbItem
  },

  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        controlFrameworkId: null,
        description: "",
        percentageCompleted: null,
        controlFramework: "",
        isActive: true,
        organisationId: 0,
        controlFrameworkCategories: []
      },
      controlFrameworks: [],
      modelValidations: {
        controlFramework: {
          required: true
        }
      },
      tableColumns: [
        {
          prop: "name",
          label: "Category",
          minWidth: 300
        }
      ],
      topSelect: false,
      //surveys: [],
      users: [],
      userId: "",
      loading: true,
      loaded: false
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
    self.getAllActiveControlFrameworks();
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.operationalEffectiveness, isValid);
      });
    },
    getAllActiveControlFrameworks() {
      let self = this;
      self.$store.state.services.controlFrameworkService
        .getAll({
          params: {
            ActiveControlFrameworks: true
          }
        })
        .then(r => {
          self.controlFrameworks = r.data;
        })
        .catch(err => console.log(err));
    },

    getAllActiveUsers() {
      let self = this;
      self.$store.state.services.userService
        .getAll({
          params: {
            ActiveUsers: true
          }
        })
        .then(r => {
          self.users = r.data;
        })
        .catch(err => console.log(err));
    },

    ddlControlFrameworkChange(value) {
      let self = this;
      self.model.controlFrameworkId = value;
      self.getAllActiveControlFrameworkCategories(value);
    },

    ddlUserChange(value) {
      let self = this;
      self.userId = value;
    },

    getAllActiveControlFrameworkCategories(value) {
      let self = this;
      self.$store.state.services.controlFrameworkCategoryService
        .getAll({
          params: {
            ActiveControlFrameworkCategories: true,
            ControlFrameworkId: value
          }
        })
        .then(r => {
          self.model.controlFrameworkCategories = r.data;
          self.getAllActiveUsers();
        })
        .catch(err => console.log(err));
    },

    getOEControlAssessmentCategories(value) {
      let self = this;
      self.$store.state.services.oEControlAssessmentCategoryService
        .getAll({
          params: {
            OEControlAssessmentId: value
          }
        })
        .then(r => {
          self.model.controlFrameworkCategories = r.data;
          self.getAllActiveUsers();
          self.loading = false;
          self.loaded = true;
        })
        .catch(err => console.log(err));
    },

    get(id) {
      let self = this;
      self.$store.state.services.oEControlAssessmentService
        .get(id)
        .then(r => {
          self.model.id = r.data.id;
          self.model.controlFramework = r.data.controlFramework;
          self.model.description = r.data.description;
          self.model.percentageCompleted = r.data.percentageCompleted;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          self.model.controlFrameworkId = r.data.controlFrameworkId;
          self.getOEControlAssessmentCategories(self.$route.params.id);
        })
        .catch(err => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then(valid => {
        if (valid) {
          self.model.isActive = self.checkboxValue.toString();
          self.model.organisationId = self.$route.params.organisationId;
          self.$store.state.services.oEControlAssessmentService
            .update(self.model)
            .then(r => {
              self.notifyVue ('top', 'right', 'success');
               self.close();
            })
            .catch(err => console.log(err));
        }
      });
    },
    close() {
      let self = this;
      self.$router.push(
        "/operationaleffectiveness/" + self.$route.params.organisationId + "/oeassessments"
      );
    },
    notifyVue (verticalAlign, horizontalAlign, messageType) {
        this.$notify(
          {
            component: {
              template: `<span></br>Operational Effectiveness Assessment modified</br>&nbsp;</span>`
            },
            icon: 'fa fa-check',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: messageType
          })
      }
  }
};
</script>